import { MuxPlayerProps, MuxPlayerRefAttributes } from '@mux/mux-player-react'
import { useRef, useState, lazy } from 'react'

import { APPS } from '../../../../domain/apps/lib/apps'
import { Maybe } from '../../../../util/graphql'
import { useRunOnUnmount } from '../../../../util/hook/use-run-on-unmount'
import { logError } from '../../../../util/logging/lib/logging'
import { TypeVideoData } from '../../../../util/type/video-data'

import {
  CancellationReason,
  TCancellationReason,
  useTrackPlayback,
} from './useTrackPlayback'
// eslint-disable-next-line import/no-unresolved
const MuxPlayerReact = lazy(() => import('@mux/mux-player-react/lazy'))

export type MuxVideoPlayerAttributes = MuxPlayerRefAttributes

const DEFAULT_ASPECT_RATIO = 16 / 9
const DEFAULT_AUTOPLAY = false
const DEFAULT_STREAM_TYPE = 'on-demand'
const DEFAULT_THUMBNAIL_TIME = 1
const DEFAULT_PRELOAD = 'none'

function ratioAsDivision(ratio: string): number {
  const [width, height] = ratio.split(':')
  return width && height
    ? Number.parseInt(width, 10) / Number.parseInt(height, 10)
    : DEFAULT_ASPECT_RATIO
}

export type MuxVideoPlayerProps = Omit<
  MuxPlayerProps,
  'thumbnailTime' | 'metadata'
> & {
  handlePlaybackEnded?: () => void
  metadata: {
    video_id: string
    duration?: number
    video_title: string
    url: string
    watchId: string
    viewer_user_id: string
    fund_id?: string | string[]
  }
  src?: Maybe<string>
  ratio?: string
  preload?: 'auto' | 'metadata' | 'none'
  variant?: keyof typeof styles
  poster?: string
  autoPlay?: boolean
  streamType?: 'live' | 'll-live' | 'on-demand'
  thumbnailTime?: MuxPlayerProps['thumbnailTime'] | null
  appName?: APPS
  loading?: 'page' | 'viewport'
  fundProductIds?: TypeVideoData['fundProductIds']
}

const styles = {
  inPageComponent: 'overflow-hidden rounded-2xl',
}

export const MuxVideoPlayer = (props: MuxVideoPlayerProps) => {
  const [muted, setMuted] = useState(true)
  const videoRef = useRef<MuxVideoPlayerAttributes | null>(null)
  const metadataRef = useRef<{ duration: number; video_title: string }>({
    duration: 0,
    video_title: '',
  })
  const timeElapsedRef = useRef(0)

  const {
    playbackId,
    autoPlay = DEFAULT_AUTOPLAY,
    handlePlaybackEnded,
    metadata,
    ratio,
    src,
    streamType = DEFAULT_STREAM_TYPE,
    thumbnailTime = DEFAULT_THUMBNAIL_TIME,
    preload = DEFAULT_PRELOAD,
    variant,
    loading = 'viewport',
    ...rest
  } = props

  const { loaded, started, paused, resumed, cancelled, completed } =
    useTrackPlayback()

  // Had to set thumbnailTime value this way because of type mismatch between the lib's API and contentful being nullable
  const thumbnailTimeValue = thumbnailTime || DEFAULT_THUMBNAIL_TIME

  const handlePlaybackInterval = () => {
    const currentTime = videoRef.current?.currentTime
    if (currentTime) {
      timeElapsedRef.current = currentTime
      document.dispatchEvent(new Event('VIDEO_PLAYING'))
    }
  }

  useRunOnUnmount((isUnload) => {
    const ref = videoRef.current
    const metaRef = metadataRef.current
    if (ref && metaRef) {
      const cancelReason = isUnload
        ? CancellationReason.CLOSED
        : CancellationReason.UNMOUNT

      handleCancelled(cancelReason)
    }
  })

  const convertMetadataForRef = () => {
    const ref = metadataRef.current
    ref.video_title = metadata.video_title || ''
    // if the video being played is an embedded asset, the query won't have the duration but we can grab it from the ref
    if (!metadata.duration && videoRef.current?.duration) {
      ref.duration = videoRef.current?.duration
    } else if (metadata.duration) {
      ref.duration = metadata.duration
    }
  }

  const handleLoadedMetadata = () => {
    convertMetadataForRef()

    loaded(metadata)
  }

  const handlePlaying = () => {
    const ref = videoRef.current
    if (!ref || ref.ended) {
      return
    }
    setMuted(false)

    if (ref.currentTime > 0.15) {
      resumed(ref, {
        ...metadata,
        duration: metadataRef.current.duration,
        currentTime: timeElapsedRef.current,
      })
    } else {
      started({
        ...metadata,
        duration: metadataRef.current.duration,
      })
    }
  }

  const handlePause = () => {
    // Note: Guard against rewind firing the onPause event
    const ref = videoRef.current

    if (!ref || ref.ended) {
      return
    }

    paused(ref, {
      ...metadata,
      duration: metadataRef.current.duration,
      currentTime: timeElapsedRef.current,
    })
  }

  const handleEnded = () => {
    const ref = videoRef.current
    if (!ref) {
      return
    }

    completed({
      ...metadata,
      duration: metadataRef.current.duration,
      currentTime: timeElapsedRef.current,
    })

    ref.currentTime = 0
    timeElapsedRef.current = 0

    handlePlaybackEnded && handlePlaybackEnded()
  }

  const handleAbort = () => {
    handleCancelled(CancellationReason.SWITCH_VIDEO)
  }

  const handleError = (e: Event) => {
    const isMuxError = e instanceof CustomEvent

    const error = isMuxError
      ? new Error(e.detail.message || 'Mux Video Player Error')
      : new Error('Unknown Video Player Error')

    logError({
      error: error,
      message: error.message,
    })
  }

  const handleCancelled = (reason: TCancellationReason) => {
    const ref = videoRef.current
    const metaRef = metadataRef.current
    const timeElapsed = timeElapsedRef.current

    if (ref && !ref.ended && timeElapsed > 0) {
      cancelled(
        {
          ...metadata,
          duration: metaRef.duration,
          currentTime: timeElapsedRef.current,
          video_id: metadata.video_id,
          video_title: metaRef.video_title,
        },
        reason
      )
    }
  }

  const aspectRatio = ratio ? ratioAsDivision(ratio) : DEFAULT_ASPECT_RATIO

  return (
    <MuxPlayerReact
      key={src || playbackId}
      ref={videoRef}
      metadata={metadata}
      streamType={streamType}
      // This is necessary to prevent video from playing after use navigates away
      muted={muted}
      thumbnailTime={thumbnailTimeValue}
      onLoadedMetadata={handleLoadedMetadata}
      onPlaying={handlePlaying}
      onPause={handlePause}
      onEnded={handleEnded}
      onError={handleError}
      onTimeUpdate={handlePlaybackInterval}
      preload={preload}
      onAbort={handleAbort}
      autoPlay={autoPlay}
      src={src}
      playbackId={playbackId}
      loading={loading}
      defaultHiddenCaptions={true}
      accentColor={getColor('--colors-neutral-700')}
      style={{
        aspectRatio: aspectRatio.toString(),
        objectFit: 'cover',
        // @ts-ignore
        '--mux-player-react-lazy-placeholder': null,
      }}
      {...(variant && { className: styles[variant] })}
      {...rest}
    />
  )
}

/**
 * Get theme aware CSS variable
 * @returns string
 */
function getColor(c: string): string {
  const color = getComputedStyle(document.documentElement)?.getPropertyValue(c)
  return color ? `rgb(${color})` : ''
}
