import React from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import type { AllColor } from '../../../../../equity/particles/colors'
import type { AnnouncementProps } from '../../../../../equity/web/labs/announcement'
import { LOGOUT_ACTION } from '../../../../ui/main-nav'
import { type GetAnnouncementsQueryVariables } from '../../../../util/graphql/mfe-contentful'
import { useUserProfile } from '../../../../util/hook/use-user-profile'
import { today } from '../../../../util/time/date-time-contentful'
import { fetchInstance } from '../../api'

import { DATE_FORMAT, announcementsService } from './announcements-service'
import { Adornment } from './components/adornment'
import type { BffAnnouncementResponseType } from './components/types'

// TODO: when we move to BFF, copy over code to other hook  and remove
export const useGetBffAnnouncements = (
  variables: GetAnnouncementsQueryVariables,
  options?: {
    allowSingleDismissalPerSession?: boolean
    // TODO: Will this always be /homepage and do we need it since it will now be used in BFF?
    appUrl?: string
  }
): { announcement: AnnouncementProps | null } => {
  const { userProfileState } = useUserProfile()
  const { userProfile } = userProfileState
  const [showAnnouncement, setShowAnnouncement] = React.useState(false)
  // TODO: When we move to BFF, we will only need the userId to initiate announcements
  announcementsService.initiateAnnouncements(undefined, userProfile?.id)

  const { allowSingleDismissalPerSession = false } = options || {}

  const location = useLocation()

  const announcementsDismissedIds =
    announcementsService.announcementsDismissedIds

  const { data, isLoading } = useQuery(
    ['getAnnnouncements', variables.id],
    () =>
      fetchInstance<BffAnnouncementResponseType>({
        url: `/announcements/${variables.date || today(DATE_FORMAT)}/${
          variables.id
        }?announcements`,
        data: {
          announcementsDismissed: announcementsDismissedIds,
        },
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !announcementsService.hasDismissedThisSession,
    }
  )

  const { announcement } = data || {}

  React.useEffect(() => {
    const handleLoggingOut = () => {
      if (announcementsService.hasDismissedThisSession) {
        announcementsService.clearHasDismissed()
      }
    }
    document.addEventListener(LOGOUT_ACTION, handleLoggingOut)

    // We don't necessarily need to do this in a useEffect, but it maybe safer to do so.
    const canShow = !isLoading
      ? announcementsService.canShowAnnouncement(
          location.pathname,
          data?.excludedUrls
        )
      : false

    setShowAnnouncement(canShow)

    return () => {
      document.removeEventListener(LOGOUT_ACTION, handleLoggingOut)
    }
  }, [location.pathname, userProfile?.id, isLoading, data?.excludedUrls])

  const dismissAnnouncement = () => {
    if (announcement) {
      announcementsService.setAnnouncementsDismissed({
        announcement: {
          [announcement.id]: {
            dismissed: true,
            dateTimeEnd: announcement.dateTimeEnd,
          },
        },
        allowSingleDismissalPerSession,
      })
    }
    setShowAnnouncement(false)
  }

  return {
    announcement:
      announcement && showAnnouncement
        ? {
            ...announcement,
            onDismiss: dismissAnnouncement,
            adornment: (color: AllColor) => (
              <Adornment color={color} announcement={announcement} />
            ),
            // TODO: We could do the following in the BFF, if we pass the userProfile to the BFF
            disabled: Boolean(userProfile?.isSwitched),
            action: {
              ...announcement.action,
              handler: dismissAnnouncement,
              // TODO: We could do the following in the BFF, if we pass the userProfile to the BFF
              disabled: Boolean(userProfile?.isSwitched),
            },
          }
        : null,
  }
}
